import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Leetcode from "./Leetcode";
import Techstack from "./Techstack";
import Timeline from "./Timeline";
import Toolstack from "./Toolstack";

function About() {
	return (
		<Container fluid className="about-section">
			<Particle />
			<Container>
				<Row style={{ justifyContent: "center", padding: "10px" }}>
					<Col
						md={7}
						style={{
							justifyContent: "center",
							paddingTop: "30px",
							paddingBottom: "50px",
						}}
					>
						<h1
							style={{ fontSize: "2.1em", paddingBottom: "20px" }}
						>
							Know Who <strong className="purple">I'M</strong>
						</h1>
						<Aboutcard />
					</Col>
					<Col
						md={5}
						style={{ paddingTop: "120px", paddingBottom: "50px" }}
						className="about-img"
					>
						<img
							src="/assets/about.png"
							alt="about"
							className="img-fluid"
						/>
					</Col>
				</Row>

				<h1 className="project-heading">
					WORK <strong className="purple">Experience </strong>
				</h1>

				<Timeline />
				<br />
				<br />

				<h1 className="project-heading">
					Professional <strong className="purple">Skillset </strong>
				</h1>

				<Techstack />

				<h1 className="project-heading">
					<strong className="purple">Tools</strong> I use
				</h1>

				<Toolstack />

				<Leetcode
					username="karan-gaur"
					blockSize={20}
					blockMargin={8}
					color="#c084f5"
					fontSize={16}
				/>
			</Container>
		</Container>
	);
}

export default About;
