import React from "react";
import { Button, Card, Row } from "react-bootstrap";
import { BsGithub } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import "./ProjectCards.css";

const ProjectCard = (props) => {
	return (
		<Card className="project-card-horizontal-view">
			{/* TODO: Move all inline css to external css file */}
			<Row className="no-gutters">
				<div
					className="no-padding-margin"
					style={{ width: "35%", height: "auto" }}
				></div>
				<div
					className="no-padding-margin"
					style={{ width: "65%", height: "auto" }}
				>
					<Card.Title className="project-card-title">
						{props.title}
					</Card.Title>
				</div>
			</Row>
			<Row className="no-gutters">
				<div
					className="no-padding-margin"
					style={{ width: "35%", height: "auto" }}
				>
					<img width="90%" src={props.imgPath} alt="project-img" />
				</div>
				<div
					className="no-padding-margin"
					style={{ width: "65%", height: "auto" }}
				>
					<Card.Body className="project-card-body">
						<Card.Text className="project-card-description">
							{props.description}
						</Card.Text>
						<div className="button-container">
							<Button
								variant="primary"
								href={props.ghLink}
								target="_blank"
							>
								<BsGithub /> &nbsp;
								{props.isBlog ? "Blog" : "GitHub"}
							</Button>

							{!props.isBlog && props.demoLink && (
								<Button
									variant="primary"
									href={props.demoLink}
									target="_blank"
								>
									<CgWebsite /> &nbsp;
									{"Demo"}
								</Button>
							)}
						</div>
					</Card.Body>
				</div>
			</Row>
		</Card>
	);
};

export default ProjectCard;
