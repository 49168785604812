import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
	return (
		<Typewriter
			options={{
				strings: [
					"Software Developer",
					"DevOps Engineer",
					"DSA Enthusiast",
					"Open Source Contributor",
					"Freelancer",
				],
				autoStart: true,
				loop: true,
				delay: 50,
				deleteSpeed: 15,
			}}
		/>
	);
}

export default Type;
