import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
	return (
		<Card className="quote-card-view">
			<Card.Body>
				<blockquote className="blockquote mb-0">
					<p style={{ textAlign: "justify" }}>
						Hi Everyone, I am{" "}
						<span className="purple">Karan Gaur </span>
						from{" "}
						<span className="purple"> Harrison, New Jersey.</span>
						<br />
						I am a Computer Science Graduate from New Jersey Inst of
						Tech
						<br />
						<br />
						<br />
						Apart from coding, some other activities that I love to
						do!
					</p>
					<ul>
						<li className="about-activity">
							<ImPointRight /> Playing Chess / Board Games
						</li>
						<li className="about-activity">
							<ImPointRight /> Writing Blogs
						</li>
						<li className="about-activity">
							<ImPointRight /> Hiking
						</li>
						<li className="about-activity">
							<ImPointRight /> I love LEETCODE
						</li>
					</ul>

					<p style={{ color: "rgb(155 126 172)" }}>
						"Write code not just to solve problems, but to create
						possibilities."{" "}
					</p>
					<footer className="blockquote-footer">Karan Gaur</footer>
				</blockquote>
			</Card.Body>
		</Card>
	);
}

export default AboutCard;
