import React, { useCallback, useEffect, useState } from "react";
import Calendar, { Skeleton } from "react-activity-calendar";
import { Row } from "react-bootstrap";

// Constants
const LEETCODE_API_URL = "https://leetcode-stats-api.herokuapp.com/";

// Utility function to transform data
const transformData = (submissionCalendar) => {
	return Object.entries(submissionCalendar).map(([timestamp, count]) => ({
		date: new Date(parseInt(timestamp) * 1000).toISOString().split("T")[0],
		level: count > 4 ? 4 : count,
	}));
};

// Main component
const Leetcode = ({
	username,
	labels,
	transformTotalCount = true,
	errorMessage = `Error Fetching LeetCode submission data for "${username}" failed.`,
	...props
}) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [totalCount, setTotalCount] = useState(0);

	const fetchData = useCallback(() => {
		setLoading(true);
		setError(null);
		fetchCalendarData(username)
			.then((data) => {
				setData(data);
				const total = Object.values(data.submissionCalendar).reduce(
					(sum, count) => sum + count,
					0
				);
				setTotalCount(total);
			})
			.catch(setError)
			.finally(() => setLoading(false));
	}, [username]);

	useEffect(fetchData, [fetchData]);

	async function fetchCalendarData(username) {
		const response = await fetch(`${LEETCODE_API_URL}${username}`);
		const data = await response.json();

		if (!response.ok) {
			throw Error(
				`Fetching LeetCode submission data for "${username}" failed: ${data.error}`
			);
		}
		return data;
	}

	if (error) {
		return <div>{errorMessage}</div>;
	}

	if (loading || !data) {
		return <Skeleton {...props} loading />;
	}

	const theme = {
		level4: "#470185", // Darkest shade
		level3: "#471078", // Dark shade
		level2: "#53267a", // Medium shade
		level1: "#654582", // Light shade
		level0: "#ae9dbd", // Lightest shade (background color)
	};
	const defaultLabels = {
		totalCount: `${totalCount} submissions in the last year`,
	};

	return (
		<Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
			<h1 className="project-heading" style={{ paddingBottom: "20px" }}>
				Days I <strong className="purple">LEETCODE</strong>
			</h1>
			<Calendar
				data={transformData(data.submissionCalendar)}
				theme={theme}
				labels={Object.assign({}, defaultLabels, labels)}
				{...props}
				loading={Boolean(props.loading) || loading}
				maxLevel={4}
			/>
		</Row>
	);
};

export default Leetcode;
