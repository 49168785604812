import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
	AiFillGithub,
	AiFillInstagram,
	AiOutlineTwitter,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Tilt from "react-parallax-tilt";

function Home2() {
	return (
		<Container fluid className="home-about-section" id="about">
			<Container>
				<Row>
					<Col md={8} className="home-about-description">
						<h1 style={{ fontSize: "2.6em" }}>
							LET ME <span className="purple"> INTRODUCE </span>{" "}
							MYSELF
						</h1>
						<p className="home-about-body">
							I fell in love with programming and I have learnt
							something, I think… 🤷‍♂️
							<br />
							<br />I am fluent in classics like
							<i>
								<b className="purple">
									{" "}
									Javascript, Go, Java and Python. (Yup Pretty
									much all of them){" "}
								</b>
							</i>
							<br />
							<br />
							My field of Interest's are building new &nbsp;
							<i>
								<b className="purple">
									Web Technologies and Products{" "}
								</b>{" "}
								and also in areas related to{" "}
								<b className="purple">Blockchain.</b> (Something
								I started recently. -?- cause sounds COOL!!)
							</i>
							<br />
							<br />
							Whenever possible, I also apply my passion for
							developing & designing products. Emphasizing on{" "}
							<b className="purple">High Level Designing</b> and
							<i>
								<b className="purple">
									{" "}
									Flow & Schema Designing.
								</b>
							</i>
						</p>
					</Col>
					<Col md={4} className="myAvtar">
						<Tilt>
							<img
								src="/assets/avatar.svg"
								className="img-fluid"
								alt="avatar"
							/>
						</Tilt>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="home-about-social">
						<h1>FIND ME ON</h1>
						<ul className="home-about-social-links">
							<li className="social-icons">
								<a
									href="https://github.com/karan-gaur"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
								>
									<AiFillGithub />
								</a>
							</li>
							<li className="social-icons">
								<a
									href="https://twitter.com/karan_gaur_"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
								>
									<AiOutlineTwitter />
								</a>
							</li>
							<li className="social-icons">
								<a
									href="https://www.linkedin.com/in/karan-gaur/"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
								>
									<FaLinkedinIn />
								</a>
							</li>
							<li className="social-icons">
								<a
									href="https://www.instagram.com/karan_gaur_"
									target="_blank"
									rel="noreferrer"
									className="icon-colour home-social-icons"
								>
									<AiFillInstagram />
								</a>
							</li>
						</ul>
						<p>
							Feel free to{" "}
							<span className="purple">connect </span>with me
						</p>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}
export default Home2;
